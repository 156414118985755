<template>
  <onboarding-scaffold>
    <template v-slot:content>
      <NotificationToast
        v-if="notification"
        :notification="notification"
        @resetNotification="notification = null"
      />
      <div class="w-full flex items-center justify-center">
        <div
          class="
            xl:w-1/2
            lg:w-full
            md:w-full
            w-full
            min-h-screen
            py-20
            xl:px-28
            lg:px-0
            md:px-0
            px-0
          "
        >
          <form-scaffold
            :loading="submitting"
            button-text="Login"
            @sendAction="send"
          >
            <span slot="heading-text">Login to Continue</span>
            <div slot="field-instructions">
              <span class="text-legend-gray dark:text-border-color mr-5"
                >Don’t have an account?
              </span>
              <router-link
                :to="{ name: 'Register' }"
                data-register
                class="
                  text-primary-color
                  border-b border-primary-color
                  font-bold
                "
                >Create account</router-link
              >
            </div>
            <div slot="input-fields">
              <div>
                <CustomInput
                  :fields="{
                    label: 'Username',
                    fieldName: 'username',
                    placeholder: 'e.g legend',
                  }"
                  @input-value="setValue"
                />
                <ValidationMessage v-if="loading && !$v.username.required">
                  <span slot="message">Username is required.</span>
                </ValidationMessage>
              </div>
              <div class="relative mt-6">
                <label
                  class="
                    text-legend-gray
                    dark:text-border-color
                    font-semibold
                    block
                    leading-relaxed
                  "
                  >Password</label
                >
                <input
                  v-model="password"
                  :type="inputType"
                  placeholder="e.g legend@#20"
                  data-password
                  class="
                    text-seconadry-border
                    bg-transparent
                    border border-border-color
                    rounded-lg
                    w-full
                    p-3
                    focus:outline-none
                  "
                />
                <img
                  :src="imageURL"
                  alt="toggle password visibility"
                  class="absolute right-6 bottom-4 w-7 cursor-pointer"
                  @click="togglePasswordField"
                />
              </div>
              <ValidationMessage v-if="loading && !$v.password.required">
                <span slot="message">Password is required.</span>
              </ValidationMessage>
              <div class="text-right my-2">
                <router-link
                  :to="{ name: 'Forgot' }"
                  data-forgot
                  class="text-primary-color font-semibold"
                >
                  Forgot Password?
                </router-link>
              </div>
            </div>
          </form-scaffold>
        </div>
      </div>
    </template>
  </onboarding-scaffold>
</template>

<script>
import OnboardingScaffold from '@/components/OnboardingScaffold.vue'
import FormScaffold from '@/components/FormScaffold.vue'
import CustomInput from '@/components/OnboardingInput.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Login',
  components: {
    OnboardingScaffold,
    FormScaffold,
    CustomInput,
  },
  computed: {
    imageURL() {
      let image

      this.inputType === 'password'
        ? (image = 'eye-icon.svg')
        : (image = 'eye-icon-active.svg')

      return this.getImgUrl(image)
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from

      if (vm.$store.getters.token) {
        vm.$router.go(-1)
      }
    })
  },
  mounted() {
    if (this.prevRoute && this.prevRoute.name === 'Password') {
      this.notification = {
        type: 'success',
        message: this.$route.query.message,
      }
    }
  },
  data() {
    return {
      username: null,
      password: null,
      inputType: 'password',
      loading: false,
      submitting: false,
      notification: null,
    }
  },
  validations() {
    return {
      username: { required },
      password: { required },
    }
  },
  methods: {
    getImgUrl(fileName) {
      return require(`@/assets/images/${fileName}`)
    },
    togglePasswordField() {
      if (this.inputType === 'password') {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    },
    setValue(value) {
      const currentField = value.field
      this[currentField] = value.value
    },
    async send() {
      try {
        if (this.submitting) {
          return
        }

        this.loading = true

        this.$v.$touch()

        if (this.$v.$invalid) {
          return
        }

        this.submitting = true

        const { status, data } = await this.axios.post('/login', {
          username: this.username,
          password: this.password,
        })

        if (status === 200) {
          this.$store.commit('SET_TOKEN', data)
          this.loading = false
          this.submitting = false
          const link = document.createElement('a')
          link.href = '/'
          link.click()
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.$store.commit('SET_USERNAME', this.username)
          this.$router.push({
            name: 'Verify',
            query: { message: error.response.data.message },
          })
        } else if (error.response.status === 401) {
          this.notification = {
            type: 'error',
            message: error.response.data.message,
          }
        }
        this.submitting = false
      }
    },
  },
}
</script>
